import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { MatSnackBar } from "@angular/material/snack-bar";

import { BadgesService } from "../../services/badges/badges.service";
import { ImagesService } from "../../services/helper/images.service";
import { PersonService } from "../../services/person/person.service";
import { ProjectService } from "../../services/project/project.service";
import { UserService } from "../../services/user/user.service";

import { Router } from "@angular/router";
import { Project } from "app/shared/models/project.interface";
import { Observable } from "rxjs";
import { Persona, User } from "../../shared/models/user.interface";

@Component({
	selector: "app-dialog-dar-badge",
	templateUrl: "./dialog-dar-badge.component.html",
	styleUrls: ["./dialog-dar-badge.component.scss"],
})
export class DialogDarBadgeComponent implements OnInit {
	TANDEM_PROJECT_ID = this.projectSvc.TANDEM_PROJECT_ID;

	badgeReturn: string;
	badgeStep: number;

	personsSuggestion: Array<any>;
	persons: Array<any>;
	hasMorePersons: boolean;
	currentPagePersons: number;
	competencies: any;

	filterPersonValue: string;

	userId: number;
	projectId: number;
	project: Project;
	personSelected: any;
	personsSelected: Array<any>;
	competencySelected: any;
	indicators: Array<any>;
	motivation: string;
	minIndicators: number;
	maxIndicators: number;
	minCharacters: number;
	numIndicatorsSelected: number;
	limitePersonasAlcanzado: boolean = false;

	personsTab: Observable<Persona[]>;

	badgeTab: number = 0;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { badgeReturn: string; personId: number },
		private dialogRef: MatDialogRef<DialogDarBadgeComponent>,
		private snackBar: MatSnackBar,
		private projectSvc: ProjectService,
		private imagesSvc: ImagesService,
		private badgeSvc: BadgesService,
		private userSvc: UserService,
		private personSvc: PersonService,
		private router: Router,
	) {
		this.badgeReturn = "";
		this.badgeStep = 0;
		this.filterPersonValue = "";

		this.personsSuggestion = [];
		this.persons = [];
		this.competencies = [];
		this.indicators = [];
		this.hasMorePersons = false;
		this.currentPagePersons = 0;

		this.motivation = "";

		this.personSelected = null;
		this.competencySelected = null;
		this.numIndicatorsSelected = 0;
		this.personsSelected = [];
		this.limitePersonasAlcanzado = false;
	}

	ngOnInit(): void {
		if (this.data !== undefined && this.data !== null) {
			this.badgeReturn = this.data.badgeReturn;

			if (this.data.personId) {
				this.getPerson(this.data.personId);
			}
		}

		this.projectId = parseInt(this.projectSvc.getProjectId());
		if (this.projectId == this.TANDEM_PROJECT_ID) {
			this.badgeTab = 2;
		}

		this.userSvc.getUser().subscribe(
			(data: User) => {
				this.userId = data.id;
			},
			(err) => {
				this.showSnack(err);
			},
		);

		this.projectSvc.getProjectConfig().subscribe(
			(data) => {
				this.project = data;
				this.minCharacters = this.project.badgeMinCharacters;
				this.minIndicators = this.project.badgeMinIndicators;
				this.maxIndicators = this.project.badgeMaxIndicators;
				if (!this.project.entryPoint.enabled_badges) {
					this.dialogRef.close();
				}
			},
			(err) => {
				this.showSnack(err);
			},
		);

		if (this.projectId == this.TANDEM_PROJECT_ID) {
			this.getPersonsTandem();
		} else {
			this.getPersonsSuggestion();
		}
	}

	getPersonsSuggestion() {
		this.badgeSvc.getPersonSuggestion().subscribe(
			(data) => {
				this.addSuggestion(data.lastRecievedBadgePerson);
				this.addSuggestion(data.lastDeliveredBadgedPerson);
				this.addSuggestion(data.mostBadgedPerson);
				this.addSuggestion(data.departmentPerson);
			},
			(err) => {
				this.showSnack(err);
			},
		);
	}

	private addSuggestion(person: Persona) {
		if (this.personsSuggestion.length == 0) {
			this.personsSuggestion.push(person);
		} else {
			const personFinded = this.personsSuggestion.find((item) => {
				return item.id === person.id;
			});

			if (!personFinded) {
				this.personsSuggestion.push(person);
			}
		}
	}

	filterPersons(event: Event) {
		if (this.filterPersonValue.length > 2) {
			this.currentPagePersons = 0;
			this.persons = [];
			this.getPersons();
		} else {
			this.currentPagePersons = 0;
			this.persons = [];
		}
	}

	getPersons() {
		this.personSvc.getPersons(this.filterPersonValue, this.currentPagePersons, 5).subscribe(
			(personsData) => {
				this.persons = [];
				let aux = this.persons.concat(personsData.elements);
				const dataArr = new Set(aux);
				this.persons = [...dataArr];
				/*for(let ele of personsData.elements){
					if(!this.persons.includes(ele)) this.persons.push(ele)
				}
				*/
				console.log(this.persons);
			},
			(err) => {
				this.showSnack(err);
			},
		);
	}

	getPerson(personId) {
		this.personSvc.getPerson(personId).subscribe((data) => {
			this.selectPerson(data);
		});
	}

	getCompetencies() {
		this.badgeSvc.getAvailableCompetencies(this.personSelected.id).subscribe(
			(data) => {
				this.competencies = data;
			},
			(err) => {
				this.showSnack(err);
			},
		);
	}

	getImagePerson(username: string) {
		return this.imagesSvc.getImageThumb(username);
	}

	getImagePersonGrande(username: string) {
		return this.imagesSvc.getImagePerson(username, this.projectId);
	}

	getImageCompetency(competencyId: number) {
		return this.imagesSvc.getImageCompetency(competencyId);
	}

	getImageDefaultMission() {
		return this.imagesSvc.getUrlImageDefaultMission();
	}

	getPuesto(person: Persona) {
		let puestoName = "";

		if (person.puestos.length > 0) {
			puestoName = person.puestos[0].puesto;
		}

		return puestoName;
	}

	getMision(person: Persona) {
		let mision = null;

		if (person?.puestos.length > 0) {
			mision = person.puestos[0].mision ? person.puestos[0].mision : null;
		}

		return mision;
	}

	selectCompetency(competency: any) {
		this.competencySelected = competency;

		if (this.competencySelected.indicators.length > 0) {
			this.indicators = this.competencySelected.indicators;
			for (const indicator of this.indicators) {
				indicator["isChecked"] = false;
			}
		} else {
			this.indicators = null;
		}

		// En Vygon, los valores(type=0) tienen configurado el número min y max de indicadores en el proyecto.
		// en las misiones(type=1) solo se puede seleccionar un indicador.
		if (this.projectId == 288) {
			if (this.competencySelected.type_competency === 0) {
				this.minIndicators = this.project.badgeMinIndicators;
				this.maxIndicators = this.project.badgeMaxIndicators;
			} else {
				this.minIndicators = 1;
				this.maxIndicators = 1;
			}
		}

		this.badgeStep = 3;
	}

	calNumIndicatorSelected(): void {
		this.numIndicatorsSelected = 0;
		if (this.indicators.length > 0) {
			for (const indicator of this.indicators) {
				if (indicator.isChecked) {
					this.numIndicatorsSelected++;
				}
			}
		}
	}

	checkValidData(): boolean {
		let disabled = true;

		if (this.motivation.length >= this.minCharacters) {
			if (this.competencySelected.type_competency === 0 || this.projectId == 288) {
				if (this.numIndicatorsSelected >= this.minIndicators && this.numIndicatorsSelected <= this.maxIndicators) {
					disabled = false;
				}
			} else {
				disabled = false;
			}
		}

		return disabled;
	}

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, "X", {
			duration: 3000,
		});
	}

	selectPerson(user: Persona) {
		this.personSelected = user;
		console.log("Person selected: ", this.personSelected);
		this.badgeStep = 1;

		// Badges individuales. Iberext y Arcelor
		if (this.badgeTab == 0 && ["leroymerlin", "iberext", "arcelor"].includes(this.project.name)) {
			this.badgeStep = 2;
		}

		// Badge grupales.
		if (this.badgeTab == 1) {
			if (this.personsSelected.length < this.project.maxBadgeGrantee) {
				this.filterPersonValue = "";

				if (!this.containsUser(user, this.personsSelected)) {
					this.personsSelected.push(user);
				}

				if (this.competencies.length == 0) {
					this.getCompetencies();
				}

				this.personSelected = null;
			} else {
				this.showSnack("No puede seleecionar más de " + this.project.maxBadgeGrantee + " personas.");
			}
		} else {
			this.filterPersonValue = user.displayName;
			this.getCompetencies();
		}

		this.competencySelected = null;
		this.persons = [];
	}

	clearPerson() {
		this.personSelected = null;
		this.filterPersonValue = "";

		if (this.badgeTab == 0) {
			this.badgeStep = 0;
			this.competencySelected = null;
			this.persons = [];
			this.indicators = [];
			this.competencies = [];
		}
	}

	emojiSelected(event) {
		const emoji = event.emoji && event.emoji.native ? event.emoji.native : null;
		if (emoji) {
			this.motivation = this.motivation + event.emoji.native;
		}
	}

	sendBadge(isPrivate) {
		let grantees = [];

		if (this.badgeTab == 1) {
			grantees = this.personsSelected;
		} else {
			grantees.push(this.personSelected);
		}

		let indicatorsChecked = null;
		if (this.indicators !== null) {
			indicatorsChecked = [];
			for (const itemIndicator of this.indicators) {
				if (itemIndicator.isChecked) {
					indicatorsChecked.push(itemIndicator);
				}
			}
		}

		this.badgeSvc
			.sendBadge(this.userId, grantees, this.competencySelected, indicatorsChecked, this.motivation, this.badgeReturn, isPrivate)
			.subscribe(
				(data) => {
					this.showSnack("Agradecimiento enviado correctamente");
					this.dialogRef.close();
					if (this.router.url == "/perfil" || this.router.url == "/inicio") {
						this.badgeSvc.sendUpdate("Update");
					}
				},
				(err) => {
					if (err.status === 409 && err.error.message.error === "max_badge") {
						this.showSnack("¡Has alcanzado el máximo de agadecimientos que puedes enviar!");
					} else {
						this.showSnack(err);
					}
				},
			);
	}

	onTabChanged(event) {
		this.badgeTab = event.index;
		this.clearPerson();
		if (this.projectId == this.TANDEM_PROJECT_ID) this.getPersonsTandem();
	}

	unselectPersonArray(key) {
		this.personsSelected.splice(key, 1);
	}

	containsUser(obj, list) {
		var i;
		for (i = 0; i < list.length; i++) {
			if (list[i].id === obj.id) {
				return true;
			}
		}

		return false;
	}

	getPersonsTandem() {
		if (this.badgeTab == 2) {
			this.personsTab = this.personSvc.peopleFromPuestoName("ALUMNO/A"); // Alumnos
		} else if (this.badgeTab == 3) {
			this.personsTab = this.personSvc.peopleFromPuestoName("FORMADOR/A"); // Profesores
		} else if (this.badgeTab == 4) {
			this.personsTab = this.personSvc.peopleFromPuestoName("ETI"); // ETIs
		}
	}
}
